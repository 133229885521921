<template>
  <div>
    <layout
        :activeIndex="activeIndex"
        :activeModel.sync="activeModel"
        :floor_selected.sync="floor_selected"
        :topBarItems="topBarItems"
        @setIndex="setIndex"
    >
      <template #left-1>
        <b-card>
          <b-card-title>选择电梯</b-card-title>
          <b-form-group
              v-if="activeIndex === 1"
              v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                :options="elevators"
                name="flavour-1"
                style="
                                display: grid;
                                grid-template-columns: repeat(2, auto);
                                grid-row-gap: 1.5rem;
                            "
            ></b-form-checkbox-group>
          </b-form-group>
        </b-card>
      </template>
      <template #left-0>
        <left-menu :list="list" @select="leftMenuSelect"></left-menu>
      </template>
      <template #left-2>
        <b-card>
          <b-card-title>筛选</b-card-title>

          <label>设备名称</label>
          <b-form-input
              placeholder="输入设备名称查询"
              trim
          ></b-form-input>

          <label class="mt-1">区域</label>
          <b-form-select
              v-model="selected_area"
              :options="options"
          ></b-form-select>
        </b-card>
      </template>
      <template #right-1>
        <b-row>
          <b-col
              v-for="(lift_name, lift_index) in selected"
              :key="lift_index"
              cols="2"
          >
            <elevator-item-info-card
                :lift_name="lift_name"
                @video-play="video_play"
            ></elevator-item-info-card>
          </b-col>
        </b-row>
        <!--    监控弹窗-->
        <b-modal
            id="elevator-video-modal"
            title="视频监控"
            size="lg"
            @close="selected_lift_video_url = ''"
            centered
            no-close-on-backdrop
            hide-footer
        >
          <div class="position-relative" style="height: 48rem">
            <video
                v-show="selected_lift_video_url"
                id="elevator-video-modal-box"
                class="h-100 w-100"
            ></video>
            <div
                v-if="!selected_lift_video_url"
                class="
                                h-100
                                w-100
                                text-center
                                pt-4
                                position-absolute
                            "
                style="top: 0; background-color: rgba(0, 0, 0, 0.5)"
            >
              <p class="text-center">未配置监控点位</p>
            </div>
          </div>
        </b-modal>
      </template>
      <template #right-2>
        <b-card no-body>
          <b-table
              :current-page="currentPage"
              :fields="fields"
              :items="items"
              :per-page="perPage"
              hover
              responsive
              striped
          >
            <template v-slot:cell(params)="data">
              <template
                  v-for="(param, param_index) in data.item.params"
              >
                                <span
                                    v-if="param.param_name"
                                    :style="
                                        param.cur_value === null
                                            ? 'opacity:0.3'
                                            : ''
                                    "
                                >
                                    <span class="mx-50" v-if="param_index !== 0"
                                    >,</span
                                    >
                                    <span>{{
                                        param.param_name.replace(
                                            data.item.equip_name + "-",
                                            ""
                                        )
                                      }}</span
                                    >:
                                    <span v-if="param.cur_value === null"
                                    >N/A</span
                                    >
                                    <span v-else>{{
                                        param.unit &&
                                        param.unit.indexOf("||") > -1
                                            ? param.unit.split("||")[
                                                param.cur_value > 0 ? 1 : 0
                                                ]
                                            : param.cur_value +
                                            " " +
                                            (param.unit || "")
                                      }}</span>
                                </span>
              </template>
            </template>
            <template #cell(action)="data">
              <div class="text-center">
                <b-button
                    v-if="data.item.ip_address"
                    variant="warning"
                    size="sm"
                >查看
                </b-button
                >
                <b-button
                    variant="success"
                    size="sm"
                    @click="equip_to_detail_model(data.item)"
                    :disabled="!data.item.detail_model_key"
                >详情
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>

        <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="items.length"
            align="right"
            first-number
            hide-goto-end-buttons
            last-number
            next-class="next-item"
            prev-class="prev-item"
        ></b-pagination>
      </template>
      <template #right-3>
        <panel-group
            :show-title="false"
            group-uid="6d4d98cc"
        ></panel-group>
      </template>
    </layout>
  </div>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import {computed, onMounted, onUnmounted, reactive, toRefs, watch,} from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import {getCurrentLiftData} from "@/api/jsf35";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flvjs from "flv.js";
import ElevatorItemInfoCard
  from "@/views/front/jsf35/subsystems/components/intelligent-elevator/elevator_item_info_card";

export default {
  name: "JsfIntelligentElevator",
  components: {
    Layout,
    LeftMenu,
    VueSlider,
    ElevatorItemInfoCard,
    panelGroup: () => import("@/views/panel-group"),
  },
  directives: {
    Ripple,
  },
  setup() {
    const arr = [];

    for (let i = 1; i < 21; i++) {
      let key = i < 10 ? "0" + i : i;
      arr.push("equipment_Lift-" + key);
    }

    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: {
            path: "elevator/elevator",
            dbIds: "equipment_Lift",
            colorDbIds: "Shaft",
            moveDbIds: arr,
            moveData: {},
          },
        },
        {title: "电梯状态"},
        {title: "设备列表"},
        /* { title: "数据分析" }, */
      ],
    });

    const data = reactive({
      selected_lift_video_url: "",
      index: 0,
      selected_area: 0,
      selected: [],
      floor_selected: "",
      options: [
        {value: 0, text: "全部"},
        {value: 1, text: "南楼"},
        {value: 2, text: "北楼"},
        {value: 3, text: "地下"},
      ],
      lift_position_list: {
        15: "F12",
        14: "F11",
        13: "F10",
        12: "F9",
        11: "F8",
        10: "F7",
        9: "F6",
        8: "F5",
        7: "F3",
        6: "F2",
        5: "F1",
        4: "B1",
        3: "B2",
        2: "B3",
        1: "B5",
      },
      value: "1",
      flag: true,
      fields: [
        {label: "ID", key: "equip_id"},
        {label: "设备名称", key: "equip_name"},
        {label: "设备编号", key: "equip_meter_code"},
        {label: "楼层", key: "floor"},
        {label: "参数状态", key: "params"},
        {label: "操作", key: "action"},
      ],
      items: [],
      perPage: 20,
      currentPage: 1,
      rows: 1,
      select: 0,
    });
    watch(
        () => store.state.jsf35.equip_list,
        (list) => {
          data.items = list;
          data.rows = list.length;
        }
    );

    //设备列表跳转到模型大样图
    const equip_to_detail_model = (item) => {
      setIndex(0);
      setTimeout(() => {
        store.commit("jsf35/setEquipSelected", item);
        leftMenuSelect(item);
      }, 1000);
    };

    const current_data = () => {
      getCurrentLiftData({
        project_id: 72,
        system_key: "intelligent-elevator",
      }).then((res) => {
        if (res.data && Object.keys(res.data).length > 0) {
          layoutParams.topBarItems[0].model.moveData = res.data;
          store.commit("jsf35/setEquipListCurrentData", res.data);
        }
      });
    };

    //左侧菜单相关参数
    const leftMenuParams = reactive({
      list: computed(() => {
        let list = [];
        for (let i = 1; i < 21; i++) {
          let key = i < 10 ? "0" + i : i;
          let item = {
            title: "L" + i,
            value: "L" + i,
            model: {
              path: "elevator/elevator",
              dbIds: "equipment_Lift",
              colorDbIds: "Shaft",
              moveDbIds: ["equipment_Lift-" + key],
              moveTrack: ["Shaft-" + key],
              moveData: {},
            },
          };
          if (i === 17 || i === 18) {
            item.title = "L" + i + "VIP";
          }
          list.push(item);
        }
        console.log('list', list)
        return list;
      }),
    });

    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      store.state.jsf35.equip_list.map((item, index) => {
        if (item.equip_name === event.title) {
          store.commit("jsf35/setEquipSelected", item);
        }
      });
      data.floor_selected = event.title.replace(" ", "");
      if (event?.equip_name) {
        event.model.isDetail = true;
        layoutParams.activeModel = event.model;
      } else {
        event.model.isDetail = false;
        layoutParams.activeModel = event.model;
      }
    };

    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };
    const elevators = computed(() => {
      return leftMenuParams.list.map((item, i) => {
        const obj = {};
        obj.text = item.title;
        if (i === 16 || i === 17) {
          obj.value = item.value + "VIP";
        } else {
          obj.value = item.value;
        }
        return obj;
      });
    });

    //获取监控画面
    const get_video = () => {
      setTimeout(() => {
        const videoDom = document.getElementById(
            "elevator-video-modal-box"
        );
        videoDom.innerHTML = null;
        let url = data.selected_lift_video_url;
        if (url) {
          const flvVideo = flvjs.createPlayer({
            type: "flv",
            url: url,
          });
          flvVideo.attachMediaElement(videoDom);
          flvVideo.load();
          flvVideo.play();
        }
      }, 3000);
    };

    //获取当前info_card的播放地址
    const video_play = (video_url) => {
      console.log("video_url", video_url);
      data.selected_lift_video_url = video_url;
      get_video();
    };

    const interval = setInterval(() => {
      current_data();
    }, 5000);

    onMounted(() => {
      current_data();
    });
    onUnmounted(() => {
      clearInterval(interval);
    });

    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      leftMenuSelect,
      setIndex,
      ...toRefs(data),
      elevators,
      equip_to_detail_model,
      get_video,
      video_play,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-lg {
  max-width: 80vh !important;
}
</style>
